<template>
  <v-app class="grey lighten-4">
    <MenuNavbar />
    <v-main>
      <router-view />
    </v-main>
    <app-footer></app-footer>
  </v-app>
</template>

<script lang="ts">
import MenuNavbar from "@/components/MenuNavbar.vue";
import AppFooter from "@/components/AppFooter.vue";
export default {
  name: "App",
  components: {
    MenuNavbar,
    AppFooter,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

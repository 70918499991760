<!-- eslint-disable -->
<template>
  <v-data-table id="daymanager-table" :headers="translateHeaders"
    :items="day_list.map((d) => ({ ...d, 'fdate': computeDisplayDate(d.date) }))"
    :sort-by.sync="[{ key: 'dayDate', order: 'desc' }]">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("daymngr.title") }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" width="auto">
          <template v-slot:activator="{ props }">
            <v-btn id="add-day-btn" v-bind="props" prepend-icon="mdi-folder-plus" variant="tonal">
              {{ $t("daymngr.addDay") }}
            </v-btn>
          </template>
          <DayAdderDialog v-model="editedItem" :isEdit="editId != null" @submit="insertOrUpdateDay"
            @cancel="cancel_add">
          </DayAdderDialog>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">{{
              $t("daymngr.confirmationDeleteTitle")
              }}</v-card-title>
            <v-card-text>{{ $t("daymngr.confirmationDelete") }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete">{{ $t("daymngr.cancel") }}</v-btn>
              <v-btn id="day-manager-confirm-delete" color="blue-darken-1" variant="text" @click="deleteItemConfirm">{{
                $t("daymngr.ok") }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon id="day-manager-edit-btn" class="me-2" size="small" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon id="day-manager-delete-btn" size="small" @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <span id="no-day-msg"> {{ $t("daymngr.emptyList") }}</span>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DayAdderDialog from "@/components/DayAdderDialog.vue";
import { createDefaultFDay } from "@/helpers/defs";
import { loadFlightDaysDb, insertOrUpdateFlightDayDb, deleteFlightDayDb } from "@/helpers/db/dayBookingDb";

export default defineComponent({
  components: {
    DayAdderDialog,
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      editedItem: createDefaultFDay(),
      day_list: [],
      editId: null,
      loading: false,
    };
  },
  async created() {
    await this.getDays();
  },

  onMount() {
    this.getDays();
  },

  methods: {
    computeDisplayDate(fdate) {
      return new Date(fdate).toLocaleDateString("fr-FR", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    insertOrUpdateDay() {
      console.log("insert to db", this.editedItem)
      insertOrUpdateFlightDayDb(this.editedItem, this.editId).then(() => {
        this.loading = false;
      });
      this.close();
    },
    cancel_add() {
      this.close();
    },
    async getDays() {
      await loadFlightDaysDb().then((dayList) => {
        this.day_list = dayList;
      });
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editId = item.id;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      deleteFlightDayDb(this.editedItem.id).then(() => {
        this.closeDelete();
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = createDefaultFDay();
        this.editId = null;
        this.getDays();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = createDefaultFDay();
        this.editId = null;
        this.getDays();
      });
    },
  },
  computed: {
    translateHeaders() {
      return [
        { title: this.$t("daymngr.table.date"), key: "fdate" },
        { title: this.$t("daymngr.table.topic"), key: "topic" },
        { title: this.$t("daymngr.table.place"), key: "place" },
        { title: this.$t('daymngr.table.isCancelled'), key: "is_cancelled" },
        { title: this.$t("daymngr.table.nbSeats"), key: "available_seats" },
        { title: this.$t("daymngr.table.nbSeatsMin"), key: "min_nb_person" },
        { title: this.$t("daymngr.table.comments"), key: "comments" },
        { title: this.$t("daymngr.table.actions"), key: "actions" },
      ];
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.close();
      }
    },
    dialogDelete(val) {
      if (!val) {
        this.closeDelete();
      }
    },
  },
});
</script>

import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FB_API_KEY,
  authDomain: import.meta.env.VITE_FB_AUTH_DOMAIN,
  databaseURL:import.meta.env.VITE_FB_DATABASE_URL,
  projectId: import.meta.env.VITE_FB_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FB_STORAGEBUCKET,
  messagingSenderId: import.meta.env.VITE_FB_MESSAGINGSENDERID,
  appId: import.meta.env.VITE_FB_APPID,
  measurementId: import.meta.env.VITE_FB_MEASUREMENTID,
}

const fbapp = initializeApp(firebaseConfig);
const db = getFirestore(fbapp);
const analytics = null;

if(import.meta.env.VITE_USE_EMULATOR==='true'){
  connectFirestoreEmulator(db, '127.0.0.1', 8085);
}


if(import.meta.env.VITE_USE_ANALYTICS==='true'){
  const analytics = getAnalytics(fbapp);
}

export { db, fbapp, analytics, firebaseConfig };

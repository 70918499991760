import { createStore } from "vuex";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import * as Sentry from "@sentry/browser";
import { loadPersonalDataDb } from "./helpers/db/pDataDb";

const auth = getAuth();

const store = createStore({
  state() {
    return {
      user: { uid: null },
      isInstructor: false,
    };
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      if (user) {
        loadPersonalDataDb().then((pData) => { state.user.pData = pData; })
        Sentry.setUser({ email: user.email, id: user.uid });
      } else {
        Sentry.setUser(null);
      }
    },
    SET_ISINSTRUCTOR(state, isInstructor: boolean) {
      state.isInstructor = isInstructor;
    },
  },
  actions: {
    initAuth({ commit }) {
      onAuthStateChanged(auth, (user) => {
        commit("SET_USER", user);
        commit("SET_ISINSTRUCTOR", false);
        if (auth.currentUser) {
          auth.currentUser.getIdTokenResult().then((idTokenRes) => {
            const isInstructor = idTokenRes.claims.role == "instructor";
            commit("SET_ISINSTRUCTOR", isInstructor)
          })
        }
      });
    },
  },
});

export { store };

<template>
  <div class="locale-changer">
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.code">
        {{ lang.code.toLocaleUpperCase() }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: "locale-changer",
  data() {
    return {
      langs: [
        { code: "fr", text: "Français" },
        { code: "en", text: "English" },
        //{ code: "de", text: "Deutsch" },
      ],
    };
  },
});
</script>

<template>
  <v-card>
    <v-card-title>{{
      isEdit ? $t("daymngr.adder.edit") : $t("daymngr.adder.addNew")
      }}</v-card-title>
    <v-card-text>
      <v-form validate-on="submit lazy" @submit.prevent="submit" ref="form" class="px-3">
        <v-date-input id="fday-date" :min="new Date()" :label="$t('daymngr.adder.date')"
          v-model="flightDateCache"></v-date-input>
        <v-text-field id="fday-topic" v-model="flight.topic" :label="$t('daymngr.adder.topic')" :rules="inputRules"
          prepend-icon="mdi-paragliding">
        </v-text-field>
        <v-text-field id="fday-place" v-model="flight.place" :label="$t('daymngr.adder.place')" :rules="inputRules"
          prepend-icon="mdi-parking">
        </v-text-field>
        <v-number-input id="fday-nbseats" control-variant="split" v-model="flight.available_seats" :max="20" :min="1"
          :step="1" :label="$t('daymngr.table.nbSeats')">
        </v-number-input>
        <v-number-input id="fday-nbseats-min" control-variant="split" v-model="flight.min_nb_person" :max="20" :min="1"
          :step="1" :label="$t('daymngr.table.nbSeatsMin')">
        </v-number-input>
        <v-checkbox :label="$t('daymngr.adder.isCancelled')" v-model="flight.is_cancelled"></v-checkbox>
        <v-textarea id="fday-comments" v-model="flight.comments" :label="$t('daymngr.table.comments')"
          :rules="inputRules" prepend-icon="mdi-comment">
        </v-textarea>
        <v-spacer></v-spacer>
        <v-btn id="fday-submit" @click="submit" :loading="loading" append-icon="mdi-chevron-right" block variant="text">
          {{
            isEdit
              ? $t("flight_adder.confirm_edit")
              : $t("flight_adder.confirm_new")
          }}
        </v-btn>
        <v-btn id="fday-cancel" @click="cancel" :loading="loading" append-icon="mdi-chevron-right" block variant="text">
          {{ $t("flight_adder.cancel") }}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { format } from "date-fns/format";
import { VDateInput, VNumberInput } from "vuetify/lib/labs/components.mjs";
import { createDefaultFDay } from "@/helpers/defs"
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    VDateInput,
    VNumberInput
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      menu: false,
      flightDateCache: null,
      flight: createDefaultFDay(),
      loading: false,
    };
  },
  computed: {
    inputRules() {
      return [
        (v) => !!v || this.$t("flight_adder.mandatory_field"),
        (v) => v.length >= 3 || this.$t("flight_adder.too_short"),
      ];
    },
    formData() {
      return this.modelValue;
    },
    formattedDate() {
      return this.flight.date ? format(this.flight.date, "dd MMM yyyy") : "";
    },
    maxDate() {
      return format(new Date(), "yyyy-MM-dd");
    },
  },
  methods: {
    submit() {
      console.log("subb", this.flight)
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.flight.date = format(this.flightDateCache, "yyyy-MM-dd");
        this.$emit("update:modelValue", { ...this.flight });
        this.$emit("submit");
        this.loading = false;
      }
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  mounted() {
    this.flight = {
      ...this.modelValue,
      date: new Date(this.modelValue.date),
    };
    this.flightDateCache = new Date(this.modelValue.date);
    console.log("onmount edit", this.flight)
  },
});
</script>

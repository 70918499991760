import { createRouter, createWebHashHistory } from "vue-router";
import UIHome from "@/views/user/UIHome.vue";
import FlyDays from "@/views/user/FlightDays.vue";
import PilotProfile from "@/views/user/FlightMissions.vue";
import PilotMaterial from "@/views/user/PilotMaterial.vue";
import FlightDiary from "@/views/user/FlightDiary.vue";
import PersonalData from "@/views/user/PersonalData.vue";
import RegisterPage from "@/views/RegisterPage.vue";
import LoginPage from "@/views/LoginPage.vue";
import { getAuth } from "firebase/auth";
import DataPolicy from "@/views/DataPolicy.vue";
import DayManager from "@/views/instructor/DayManager.vue";
import StudentManager from "@/views/instructor/StudentManager.vue";
import { store } from "@/store";

const routes = [
  {
    path: "/",
    name: "Accueil",
    meta: {
      requiresAuth: true,
    },
    component: UIHome,
  },
  {
    path: "/myflightdays",
    name: "Les journées de vols",
    meta: {
      requiresAuth: true,
    },
    component: FlyDays,
  },
  {
    path: "/myflightmissions",
    name: "Mon Mes missions de vol",
    meta: {
      requiresAuth: true,
    },
    component: PilotProfile,
  },
  {
    path: "/myflightdiary",
    name: "Mon carnet de fol",
    meta: {
      requiresAuth: true,
    },
    component: FlightDiary,
  },
  {
    path: "/myflightmaterial",
    name: "Mon matériel",
    meta: {
      requiresAuth: true,
    },
    component: PilotMaterial,
  },
  {
    path: "/mypersonaldata",
    name: "Mes données personnelles",
    component: PersonalData,
  },
  // {
  //   path: '/settings',
  //   name: ' Paramètres',
  //   meta: {
  //     requiresAuth: true,
  //   },
  //   component: SettingsPage
  // },
  {
    path: "/daymanager",
    name: "DayManager",
    component: DayManager,
    meta: {
      requiresAuth: true,
      requiresInstructor: true,
    },
  },
  {
    path: "/studentmanager",
    name: "StudentManager",
    component: StudentManager,
    meta: {
      requiresAuth: true,
      requiresInstructor: true,
    },
  },
  {
    path: "/login",
    name: " Se connecter",
    component: LoginPage,
  },
  {
    path: "/register",
    name: " S'enregister",
    component: RegisterPage,
  },
  {
    path: "/datapolicy",
    name: "Data policy",
    component: DataPolicy,
  },
];

const app_router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// Navigation guard
app_router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresInstructor = to.matched.some(
    (record) => record.meta.requiresInstructor,
  );
  const auth = getAuth();
  const isInstructor = store.state.isInstructor;

  // Check if user is authenticated
  const unsubscribe = auth.onAuthStateChanged((user) => {
    // If route requires auth and user is not authenticated
    if (requiresAuth && !user) {
      next({ path: "/login" }); // redirect to login
    } else if (to.path == "/login" && user) {
      next({ path: "/" });
    } else if (requiresInstructor && !isInstructor) {
      next({ path: "/" });
    } else {
      next(); // proceed to the route
    }
    unsubscribe(); // stop listening for changes after handling the navigation
  });
});

export { app_router };

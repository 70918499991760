<template>
  <div>
    <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">

      <div class="text-subtitle-1 text-medium-emphasis">
        {{ $t("login.titleSocial") }}
      </div>
      <v-container>
        <v-btn v-for="item in thirdPartyProvider" @click="thirdPartySignin(item.name)" :disabled="!item.enabled"
          color="primary" class="ma-2" :prepend-icon="item.icon" variant="outlined">{{
            item.displayName }}
        </v-btn>
      </v-container>

      <v-divider :thickness="5" color="primary">{{ $t("login.or") }}</v-divider>
      <div class="text-subtitle-1 text-medium-emphasis">
        {{ $t("login.titleEmail") }}
      </div>

      <v-text-field id="login-email" v-model="email" density="compact" :placeholder="$t('login.email')"
        prepend-inner-icon="mdi-email-outline" variant="outlined"></v-text-field>
      <v-text-field id="login-pwd" v-model="password" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
        :type="visible ? 'text' : 'password'" density="compact" :placeholder="$t('login.pwd')"
        prepend-inner-icon="mdi-lock-outline" variant="outlined"
        @click:append-inner="visible = !visible"></v-text-field>

      <v-btn id="login-submit" block class="mb-8" size="large" variant="outlined" @click="submit">
        {{ $t("login.login") }}
      </v-btn>
      <v-btn color="blue" size="small" variant="text" @click="forgotPassword">
        {{ $t("login.forgot_pwd") }}
      </v-btn>

      <v-card id="login-error-msg" v-if="connectionErrorMessage" color="error">
        <v-card-text>{{ connectionErrorMessage }}</v-card-text>
      </v-card>
      <v-card v-if="resetEmailSent == true" color="confirm">
        <v-card-text> {{ $t("login.recovery_sent_msg") + email }}</v-card-text>
      </v-card>

      <v-divider :thickness="5" color="primary"></v-divider>

      <v-card-text class="text-center">
        <v-btn id="register-btn" color="primary" size="large" variant="outlined" append-icon="mdi-chevron-right"
          @click="$router.push('/register')">
          {{ $t("login.create_account") }}
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import * as Sentry from "@sentry/browser";
import { defineComponent } from "vue";
import { signInWithThirdParty } from "@/helpers/auth";
import { store } from "@/store";
import { loadPersonalDataDb, updatePersonalDatatDb } from "@/helpers/db/pDataDb";

export default defineComponent({
  data: () => ({
    visible: false,
    email: "",
    password: "",
    connectionErrorMessage: null as string | null,
    resetEmailSent: null as boolean | null,
    thirdPartyProvider: [{ name: "google", icon: "mdi-google", displayName: "Google", enabled: true },
    { name: "facebook", icon: "mdi-facebook", displayName: "Facebook", enabled: true },
      //{ name: "apple", icon: "mdi-apple", displayName: "Apple", enabled: false }
    ]
  }),
  methods: {
    thirdPartySignin(provider: string) {

      signInWithThirdParty(provider).then(() => {
        loadPersonalDataDb().then((pData) => {
          let modif = false
          if (!pData.firstname || pData.firstname.length == 0 && store.state.user.displayName.length) {
            pData.firstname = store.state.user.displayName
            modif = true
          }
          if (!pData.email || pData.email.length == 0 && store.state.user.email.length) {
            pData.email = store.state.user.email
            modif = true
          }
          if (!pData.phone || pData.phone.length == 0 && store.state.user.phone.length) {
            pData.phone = store.state.user.phoneNumber
            modif = true
          }
          updatePersonalDatatDb(pData).then(() => { this.$router.push(modif ? "/mypersonaldata" : "/myflightdays"); })
        })
      }).catch((error) => {
        switch (error.code) {
          case "auth/popup-closed-by-user":
          case "auth/cancelled-popup-request":
            break
          case 'auth/account-exists-with-different-credential':
            this.connectionErrorMessage = this.$t("login.err_credentials_exist")
            break;
          default:
            Sentry.captureException(error);
            this.connectionErrorMessage = error.message
        }
      })
    },
    submit() {
      this.connectionErrorMessage = null;
      this.resetEmailSent = null;
      const auth = getAuth();
      auth.languageCode = this.$i18n.locale;
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(() => {
          loadPersonalDataDb().then((pData) => {
            if (!pData.email || pData.email.length == 0 && store.state.user.email.length) {
              pData.email = store.state.user.email
            }
            updatePersonalDatatDb(pData)
          })
          this.$router.push("/myflightdays");
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/invalid-email":
              this.connectionErrorMessage = this.$t("login.err_invalid_email");
              break;
            case "auth/user-not-found":
              this.connectionErrorMessage = this.$t("login.err_user_not_found");
              break;
            case "auth/missing-password":
              this.connectionErrorMessage = this.$t("login.err_missing_pwd");
              break;
            case "auth/wrong-password":
              this.connectionErrorMessage = this.$t("login.err_wrong_pwd");
              break;
            case "auth/invalid-credential":
              this.connectionErrorMessage = this.$t(
                "login.err_invalid_credentials",
              );
              break;
            default:
              this.connectionErrorMessage = this.$t("login.err_unexpected");
              Sentry.captureException(error);
              break;
          }
        });
    },
    forgotPassword() {
      this.connectionErrorMessage = null;
      this.resetEmailSent = null;
      const auth = getAuth();
      auth.languageCode = this.$i18n.locale;
      sendPasswordResetEmail(getAuth(), this.email)
        .then(() => {
          this.resetEmailSent = true;
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/missing-email":
              this.connectionErrorMessage = this.$t(
                "login.err_missing_recovery_email",
              );
              break;
            default:
              this.connectionErrorMessage = this.$t(
                "login.err_unexpected_recovery",
              );
              Sentry.captureException(error);
              break;
          }
        });
    },
  },
});
</script>

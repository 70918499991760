import { doc, getDoc, onSnapshot, query, QueryDocumentSnapshot, setDoc } from "firebase/firestore";
import { db } from "@/firebase_init";
import { store } from "@/store";
import { createDefaultPersonalData, PersonalData } from "../defs";

const docId = "pData";

function getCollectionName() {
  return "users/" + store.state.user.uid + "/pData";
}

async function updatePersonalDatatDb(pData: PersonalData) {
  const ref = doc(db, getCollectionName(), docId).withConverter(
    PersonalDataConverter,
  );
  return setDoc(
    ref,
    new PersonalData(
      pData.firstname,
      pData.name,
      pData.email,
      pData.phone,
      pData.urgencyPhone,
    ),
  );
}

function subscribeToPData(callback) {
  const ref = doc(db, getCollectionName(), docId).withConverter(
    PersonalDataConverter,
  );
  const unsubscribe = onSnapshot(ref, (doc) => {
    callback(doc.data())
  });
  // Return the unsubscribe function so the listener can be removed later
  return unsubscribe;
}

async function loadPersonalDataDb() {
  const ref = doc(db, getCollectionName(), docId).withConverter(
    PersonalDataConverter,
  );
  const docSnap = await getDoc(ref);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return createDefaultPersonalData();
}

const PersonalDataConverter = {
  toFirestore: (pData: PersonalData) => {
    return {
      firstname: pData.firstname,
      name: pData.name,
      email: pData.email,
      phone: pData.phone,
      urgencyPhone: pData.urgencyPhone,
    };
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data();
    return new PersonalData(
      data.firstname,
      data.name,
      data.email,
      data.phone,
      data.urgencyPhone,
    );
  },
};

export {
  updatePersonalDatatDb,
  loadPersonalDataDb,
  PersonalDataConverter,
  createDefaultPersonalData,
  subscribeToPData
};

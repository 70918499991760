<template>
  <v-sheet class="mx-auto">
    <v-form validate-on="submit lazy" @submit.prevent="submit" ref="form" class="px-3" width="auto">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field id="pg-manufacturer" v-model="material.pg_manufacturer"
              :label="$t('material.pg_manufacturer')"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field id="pg-model" v-model="material.pg_model" :label="$t('material.pg_model')"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field id="pg-color" v-model="material.pg_color" :label="$t('material.pg_color')"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field id="pg-ptv" v-model="material.pg_ptv" :label="$t('material.pg_ptv')"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field id="pg-rescue-manufacturer" v-model="material.rescue_manufacturer"
              :label="$t('material.rescue_manufacturer')"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field id="pg-rescue-model" v-model="material.rescue_model"
              :label="$t('material.rescue_model')"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field id="pg-rescue-ptv" v-model="material.rescue_ptv"
              :label="$t('material.rescue_ptv')"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-date-input prepend-icon="" prepend-inner-icon="$calendar" clearable :max="new Date()"
              :label="$t('material.purchase_date')" v-model="rescueDate" ref="vdateinput"></v-date-input>
          </v-col>
        </v-row>
      </v-container>

      <v-btn id="pg-submit" class="me-4" @click="submit">
        {{ $t("confirmation.save") }}
      </v-btn>
      <v-btn id="pg-cancel" class="me-4" @click="cancel">
        {{ $t("confirmation.cancel") }}
      </v-btn>

      <v-spacer></v-spacer>
    </v-form>
    <ConfirmationDialog ref="confirmationDialog"></ConfirmationDialog>
  </v-sheet>
</template>

<script lang="ts">
import ConfirmationDialog from "@/components/particles/ConfirmationDialog.vue";
import {
  loadMaterialDataDb,
  updateMaterialDatatDb,
} from "@/helpers/db/mDataDb";
import { createDefaultMaterialData } from "@/helpers/defs";
import { format } from "date-fns";
import { defineComponent } from "vue";
import { VDateInput } from "vuetify/lib/labs/components.mjs";
export default defineComponent({
  components: {
    ConfirmationDialog,
    VDateInput,
  },
  data() {
    return {
      material: createDefaultMaterialData(),
      loading: false,
      rescueDate: null,
    };
  },
  computed: {},
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.$refs.vdateinput.$el.querySelector("input").value) {
          // Hack to be able to clear v-date-input
          this.material.rescue_date = format(this.rescueDate, "yyyy-MM-dd");
        } else {
          this.material.rescue_date = null;
        }
        updateMaterialDatatDb(this.material).then(() => {
          this.loading = false;
          this.$refs.confirmationDialog.openDialog();
        });
      }
    },
    cancel() {
      this.loading = true;
      loadMaterialDataDb().then((data) => {
        this.material = data;
        this.rescueDate = this.material.rescue_date
          ? new Date(this.material.rescue_date)
          : null;
        this.loading = false;
      });
    },
  },
  beforeMount() {
    this.loading = true;
    loadMaterialDataDb().then((data) => {
      this.material = data;
      this.rescueDate = this.material.rescue_date
        ? new Date(this.material.rescue_date)
        : null;
      this.loading = false;
    });
  },
});
</script>

import {
  getDocs,
  collectionGroup,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "@/firebase_init";
import  { Student } from "../defs";

async function loadStudentListDb(): Promise<Array<Student>> {
  const pdataQuery = collectionGroup(db, "pData").withConverter(StudentConverter);
  const querySnapshot = await getDocs(pdataQuery);
  return querySnapshot.docs.map((doc) => ({
    user_id: doc.ref.path,
    firstname: doc.data().firstname,
    lastname: doc.data().lastname,
    phone: doc.data().phone,
    urgencyPhone: doc.data().urgencyPhone
    //...doc.data(),
  }));
}

// async function loadPersonalDataFromUserId(userId) {
//   const ref = doc(db, `users/${userId}/pData`, "pData").withConverter(
//     PersonalDataConverter,
//   );
//   const docSnap = await getDoc(ref);
//   if (docSnap.exists()) {
//     return docSnap.data();
//   }
//   return createDefaultPersonalData();
// }


const StudentConverter = {
    toFirestore: (student:Student) => {
        return {
            firstname: student.firstname,
            lastname: student.lastname,
            phone: student.phone,
            urgencyPhone: student.urgencyPhone
        };
    },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
        const data = snapshot.data();
        return new Student(snapshot.id, data.firstname, data.lastname, data.phone, data.urgencyPhone);
    }
};

export { loadStudentListDb};

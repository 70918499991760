<template>
  <v-container>
    <v-row v-if="!fdays.length">
      <v-card>
        <v-card-text>{{ $t("flight_day.noday") }}</v-card-text>
      </v-card>
    </v-row>

    <v-row v-else>
      <v-col :key="item.id" v-for="item in sortedFDays">
        <v-card class="mx-auto" max-width="344">
          <v-card-title>
            {{ formatfDate(item.date) }}
          </v-card-title>

          <v-card-subtitle>
            {{ item.topic }} {{ $t("flight_day.at") }} {{ item.place }} <br />

            {{ buildComment(item) }}
          </v-card-subtitle>

          <v-card-text>
            {{ item.nbFreeSeats }} {{ $t("flight_day.free_seats") }}
          </v-card-text>

          <v-card-actions>
            <v-btn @click="toggleEnrollPilot(item)" variant="outlined" :disabled="item.nbFreeSeats <=0 && !item.amIEnrolled">
              {{ getbtntext(item) }}</v-btn>
            <v-chip v-if="item.is_cancelled" color="red" variant="flat" prepend-icon="mdi-close">{{
              $t("flight_day.cancelled") }}</v-chip>
            <v-chip v-else-if="is_enough_enrollment(item) && item.nbFreeSeats>0" color="green" variant="flat"
              prepend-icon="mdi-checkbox-marked-circle">{{ $t("flight_day.confirmed") }}</v-chip>
            <v-chip v-else-if="is_enough_enrollment(item) && item.nbFreeSeats <= 0" color="green" variant="flat"
              prepend-icon="mdi-checkbox-marked-circle">{{ $t("flight_day.full") }}</v-chip>
            <v-chip v-else color="grey" variant="flat">
              {{ $t("flight_day.not_enough") }}</v-chip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { enrollPilot, unenrollPilot, subscribeToFDayUser } from "@/helpers/db/dayBookingDb";
import { FDay, FDayUser } from "@/helpers/defs";
import { store } from "@/store";
import type { Unsubscribe } from "firebase/auth";
import { defineComponent } from "vue";
import { useDate } from "vuetify";

export default defineComponent({
  data() {
    return {
      show: false,
      fdays: [] as FDayUser[],
      unsubscribe: null as Unsubscribe | null,
    };
  },

  computed:{
    sortedFDays(): FDayUser[] {
      function mycomparator(a: FDay, b: FDay) {
        const d1 = new Date(a.date).getTime();
        const d2 = new Date(b.date).getTime();
        return d1 - d2;
      }

      return this.fdays.sort(mycomparator).filter(el => new Date(el.date).getTime() >= new Date(new Date().setHours(0,0,0,0)).getTime());
    },
  },

  methods: {
    buildComment(item: FDayUser){
      return item.comments.length? "("+item.comments+")":"";
    },
    formatfDate(fdate: Date) {
      return useDate().format(fdate, "fullDateWithWeekday");
    },
    is_enough_enrollment(item: FDayUser) {
      return (
        (item.available_seats - item.nbFreeSeats) >= item.min_nb_person
      );
    },
    getbtntext(item: FDayUser) {
      return item.amIEnrolled
        ? this.$t("flight_day.unenroll")
        : this.$t("flight_day.enroll");
    },

    async toggleEnrollPilot(item: FDayUser) {
      if (item.amIEnrolled)
        await unenrollPilot(item, store.state.user.uid)
      else
        await enrollPilot(item, store.state.user.uid)
    }
  },
  created(){
    this.unsubscribe = subscribeToFDayUser((newData:FDayUser[])=>{this.fdays = newData;})
  },
  beforeDestroy(){
    if(this.unsubscribe)
      this.unsubscribe();

  },
  watch:{
  }
});
</script>

<template>
  <v-data-table
    :headers="translateHeaders"
    :items="studentList"
    sort-by.sync="firstname"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("studentmngr.title") }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
      </v-toolbar>
    </template>
    <!-- <template v-slot:item.actions="{ item }">
      <v-icon class="me-2" size="small" @click="seeDetails(item)">
        mdi-pencil
      </v-icon>
    </template> -->
    <template v-slot:no-data>
      {{ $t("studentmngr.noStudentMsg") }}
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { loadStudentListDb } from "@/helpers/db/instructorDb";
import { Student } from "@/helpers/defs";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      loading: false,
      studentList: new Array(Student),
    };
  },
  beforeMount() {
    this.loading = true;
    loadStudentListDb().then((studentList) => {
      this.studentList = studentList;
      this.loading = false;
    });
  },
  method:{
    seeDetails(item:Student){
      console.log(item)
    }
  },
  computed: {
    translateHeaders() {
      return [
        { title: this.$t("studentmngr.table.firstname"), key: "firstname" },
        { title: this.$t("studentmngr.table.lastname"), key: "name" },
        { title: this.$t("studentmngr.table.phone"), key: "phone" },
        {
          title: this.$t("studentmngr.table.urgencyPhone"),
          key: "urgencyPhone",
        },
        //{ title: this.$t('fdiary.table.actions'), key: 'actions' },
      ];
    },
  },
});
</script>

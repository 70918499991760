import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  updateDoc,
} from "firebase/firestore";
import { db } from "@/firebase_init";
import { FDay, FDayUser } from "../defs";

const collName = "fDays";

function subscribeToFDayUser(callback){
  const q = query(collection(db, collName).withConverter(FlightDayConverter));
  const unsubscribe = onSnapshot(q,(snapshot) => {
    const items = snapshot.docs.map((doc) => (doc.data()));
    callback(items);
  });
  // Return the unsubscribe function so the listener can be removed later
  return unsubscribe;
}

async function enrollPilot(fday: FDay, pilotid: string){
  const docRef = doc(db, collName,fday.id);
  await updateDoc(docRef,{enrolled_people:arrayUnion(pilotid)}) 

}

async function unenrollPilot(fday: FDay, pilotid: string){
  const docRef = doc(db, collName,fday.id);
  await updateDoc(docRef, { enrolled_people: arrayRemove(pilotid) })
}

async function insertOrUpdateFlightDayDb(flight: FDayUser, is_edit: boolean) {
  if (is_edit == null) {
    // Insert a new record
    const collref = collection(db, collName).withConverter(FlightDayConverter);
    await addDoc(collref, flight);
  } else {
    //edit current record
    const ref = doc(db, collName, flight.id).withConverter(
      FlightDayConverter,
    );
    await updateDoc(ref, flight);
  }

}


async function deleteFlightDayDb(fid: string) {
  await deleteDoc(doc(db, collName, fid));
}

async function loadFlightDaysDb(): Promise<Array<FDayUser>> {
  const docRef = collection(db, collName).withConverter(FlightDayConverter);
  const snapshot = await getDocs(docRef);
  const dataobj =  snapshot.docs.map(doc => doc.data());
  return dataobj;
}

const FlightDayConverter = {
  toFirestore: (mdata: FDayUser) => {
    return {
      date: mdata.date,
      topic: mdata.topic,
      place: mdata.place,
      is_cancelled: mdata.is_cancelled,
      available_seats: mdata.available_seats,
      min_nb_person: mdata.min_nb_person,
      comments: mdata.comments,
      enrolled_people:mdata.enrolledPeople
    };
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot): FDayUser => {
    const data = snapshot.data();
    const fday = new FDayUser(
      snapshot.id,
      data.date,
      data.topic,
      data.place,
      data.available_seats,
      data.min_nb_person,
      data.comments,
      data.is_cancelled,
      data.enrolled_people
    );
    return fday;
  },
};


export { insertOrUpdateFlightDayDb, deleteFlightDayDb, loadFlightDaysDb, enrollPilot, unenrollPilot, subscribeToFDayUser };

<template>
  <v-dialog v-model="isVisible" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">{{
        $t("confirmation.title")
        }}</v-card-title>
      <v-card-text>{{ $t("confirmation.body") }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="confirmation-ok-btn" color="blue-darken-1" variant="text" class="ml-auto"
          @click="closeDialog">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    openDialog() {
      this.isVisible = true;
    },
    closeDialog() {
      this.isVisible = false;
      this.$emit("dialog-closed");
    },
  },
});
</script>

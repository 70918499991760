<template>
  <v-container app class="text-justify">
    <h2>Cookie Policy</h2>

    <p>
      This page informs users about the technologies that help this website
      achieve the above-mentioned objectives. This cookie policy is valid for
      the chablevasion.ch website, as well as for all its sub-domains.
    </p>

    <p>
      These technologies enable the owner of this site to access and store
      information (for example, by using a cookie) or use resources (for
      example, by executing a script) on a user's device when the user interacts
      with this website.
    </p>

    <p>
      For the sake of simplicity, all these technologies are defined as
      “cookies” in this document - unless there is a reason to differentiate.
    </p>

    <p>
      This website uses both cookies managed directly by the owner and tracers
      that relate to the operation of provided by a third party. As a matter of
      principle, third-party suppliers have access to the cookies they manage.
    </p>

    <p>
      The periods of validity and expiry of cookies and other similar tracers
      may vary according to the defined by the owner or by the supplier
      concerned. Some cookies expire at the end of the user's browsing session.
    </p>

    <p>
      Some or all of the cookies described below may be stored in your browser.
      To manage how cookies are used, you can refuse the use of certain cookies
      via your Google personalization settings at any time by accessing
      g.co/privacytools. You can also manage cookies in your browser (although
      some browsers for mobile may offer this option). For example, if you use
      the Google Chrome browser, you can visit chrome://settings/cookies.
      Analysis
    </p>

    <p>
      Cookies used for analysis purposes help to collect data that will enable
      us to better understand how web users interact with a particular service.
      This information enables services to offer better-quality content and
      features that enhance the user experience.
    </p>

    <p>
      Some cookies enable site managers to better understand how users interact
      with their properties. For example, Google Analytics is a Google product
      that enables site and application owners to understand the behavior of
      Internet users using a service. Google Analytics uses cookies to collect
      information and generate reports on site traffic statistics, without
      however transmitting the identity of visitors to Google.
    </p>

    <h2>How it works</h2>

    <p>
      Function-related cookies enable users to interact with a service or site
      to access its essential functionalities. Functionalities considered
      essential to the service include preferences (such as language choice),
      product optimizations that help maintain and improve the service, and the
      retention of information about a user's session (such as the contents of a
      shopping cart).
    </p>

    <p>Some cookies are used to store a user's preferences.</p>

    <p>
      Some cookies are used to maintain and enhance the user's experience during
      a specific browsing session.
    </p>
    <h3>Personalization</h3>

    Allows storage related to personalization, such as video recommendations.

    <h3>Security</h3>

    Security-related cookies are used to authenticate users, prevent fraud and
    protect Internet users who interact with a service. Some cookies are used to
    authenticate users and ensure that only the holder of an account can access
    it. Some cookies are used to prevent spam, fraud and abuse.

    <h2>Services used on this site</h2>

    <h3>Google Analytics</h3>

    The services contained in this section enable the owner to monitor and
    analyze web traffic and may be used to track user behavior.

    <h3>Google Tag Manager</h3>

    This type of service helps the Owner to manage the tags or scripts required
    on this website centrally. As a result, user data passes through these
    services, potentially resulting in data retention.This website uses trackers
    to provide a personalized user experience by improving the quality of
    preference management options and enabling interaction with external
    networks and platforms.

    <h3>Google Maps</h3>

    Google Maps can be used on our website. This service is designed to make it
    easier for you to find your way around our premises. Google Maps uses the
    following information: Devices or applications used Parallel activities on
    Google services (searches, advertising interactions, etc.) Geographical
    position Web sites visited using Google services

    <h3>Google Search Console</h3>

    Google Search Console is used to monitor and adjust our website's ranking,
    as well as to detect any errors. This tool also enables us to remove content
    from our site as quickly as possible, should we receive a request to do so.

    <h3>YouTube</h3>

    This website integrates videos from YouTube, in particular to help digest
    our indigestible cookie policy. does not sell user information. This
    information is used to personalize their services to viewers, in particular
    to offer recommendations, personalize search results and deliver relevant
    ads. Browse or delete your YouTube activity When using this site, the Google
    Services Privacy Policy applies.

    <h2>Data processing</h2>

    By using the contact form(s) on this site, writing an e-mail or calling the
    telephone numbers listed on this site, you declare that you accept our data
    processing policy.

    <h2>Data suppression</h2>
    You can ask at any time to suppress your data by sending an email to info@chablevasion.ch
  </v-container>
</template>

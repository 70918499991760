import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  QueryDocumentSnapshot,
  updateDoc,
} from "firebase/firestore";
import { db } from "@/firebase_init";
import { store } from "@/store";
import { FDiary } from "@/helpers/defs";

function getCollectionName(): string {
  return "users/" + store.state.user.uid + "/flights";
}

async function insertOrUpdateFlightDb(flight:FDiary, is_edit:boolean):Promise<void> {
  if (is_edit == false) {
    // Insert a new record
    const ref = collection(db, getCollectionName()).withConverter(
      fDiaryConverter,
    );
    addDoc(ref,flight);
  } else {
    //edit current record
    const ref = doc(db, getCollectionName(), flight.id).withConverter(fDiaryConverter);
    updateDoc(ref, flight);
  }
}

async function deleteFlightDb(fid:string):Promise<void> {
  deleteDoc(doc(db, getCollectionName(), fid));
}

async function loadFlightsDb() {
  const docRef = collection(db, getCollectionName()).withConverter(fDiaryConverter);
  const snapshot = await getDocs(docRef);
  return snapshot.docs.map((doc) => ({ ...doc.data() }));
}

const fDiaryConverter = {
  toFirestore: (fdiary:FDiary) => {
    return {
      flight_date: fdiary.flight_date,
      takeoff_place: fdiary.takeoff_place,
      landing_place: fdiary.landing_place,
      duration_minutes: fdiary.duration_minutes,
      comments: fdiary.comments,
    };
  },
  fromFirestore: (snapshot:QueryDocumentSnapshot) => {
    const data = snapshot.data();
    return new FDiary(
      snapshot.id,
      data.flight_date,
      data.takeoff_place,
      data.landing_place,
      data.duration_minutes,
      data.comments,
    );
  },
};

export { insertOrUpdateFlightDb, deleteFlightDb, loadFlightsDb };

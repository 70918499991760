<template>
  <v-container>
    <p>{{ $t("dashboard.welcome_message1") }}</p>
    {{ $t("dashboard.welcome_message2") }}
  </v-container>
</template>

<script lang="ts">
import { loadPersonalDataDb } from '@/helpers/db/pDataDb';
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
setup(){
}
});</script>

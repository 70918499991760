<template>
  <v-app-bar id="app-bar" app fixed dark scroll-behavior="collapse" :elevation="5">
    <v-app-bar-nav-icon id="hamburger-btn" @click="drawer = !drawer" class="grey-text"
      v-if="user || smAndDown"></v-app-bar-nav-icon>
    <v-app-bar-title>{{ $t("menu.title") }}</v-app-bar-title>
    <template v-if="mdAndUp">
      <v-btn id="login-btn" v-if="user == null" @click="$router.push('/login')">{{
        $t("menu.signin")
      }}</v-btn>
      <v-btn v-if="user == null" @click="$router.push('/register')">{{
        $t("menu.register")
      }}</v-btn>
      <v-spacer></v-spacer>
      <LocaleChanger></LocaleChanger>
      <v-spacer></v-spacer>
    </template>
  </v-app-bar>
  <v-navigation-drawer app expand-on-hover rail v-model="drawer" persistent v-if="user || smAndDown">
    <!-- <v-list-item prepend-avatar="https://randomuser.me/api/portraits/men/78.jpg" title="John Leider"></v-list-item> -->
    <v-list-item :title="formatUsername()"></v-list-item>
    <v-divider></v-divider>
    <v-list density="compact" nav>
      <span v-if="user">
        <v-list-item v-for="item in loggedin_items" :key="item.title" :to="item.route" link :prepend-icon="item.icon"
          :title="$t(item.title)">
        </v-list-item>
        <template v-if="isInstructor && instructorView">
          <v-list-item v-for="item in instructor_items" :key="item.title" :to="item.route" link
            :prepend-icon="item.icon" :title="$t(item.title)">
          </v-list-item>
        </template>
      </span>
      <span v-else>
        <v-list-item v-for="item in loggedout_items" :key="item.title" :to="item.route" link>
          <v-list-item-title>{{
            $t(item.title).toUpperCase()
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <LocaleChanger></LocaleChanger>
        </v-list-item>
      </span>

      <span v-if="isInstructor && user">
        <v-switch v-model="instructorView" :label="$t('menu.enableInstructorView')"></v-switch>
      </span>
      <span v-if="user">
        <v-btn id="logout-btn" block @click="logout" prepend-icon="mdi-logout" :text="$t('menu.logout')">
        </v-btn>
      </span>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
const { smAndDown, mdAndUp } = useDisplay();
</script>

<script lang="ts">
import { useDisplay } from "vuetify";
import LocaleChanger from "@/components/particles/LocaleChanger.vue";
import { logoutUser } from "@/helpers/auth";
import { defineComponent } from "vue";
import { store } from "@/store";
import { createDefaultPersonalData, subscribeToPData } from "@/helpers/db/pDataDb";
import type { PersonalData } from "@/helpers/defs";
import type { Unsubscribe } from "firebase/auth";
// Destructure only the keys you want to use

export default defineComponent({
  components: {
    LocaleChanger,
  },
  name: "navigationBar",
  data: () => ({
    unsubscribe: null as Unsubscribe | null,
    pData: createDefaultPersonalData(),
    drawer: true,
    signinMenu: true,
    instructorView: false,
    loggedin_items: [
      { title: "menu.dashboard", icon: "mdi-view-dashboard", route: "/" },
      {
        title: "menu.flightdays",
        icon: "mdi-calendar-clock",
        route: "/myflightdays",
      },
      {
        title: "menu.flightmissions",
        icon: "mdi-check-bold",
        route: "/myflightmissions",
      },
      {
        title: "menu.flightdiary",
        icon: "mdi-notebook",
        route: "/myflightdiary",
      },
      {
        title: "menu.flightmaterial",
        icon: "mdi-paragliding",
        route: "/myflightmaterial",
      },
      {
        title: "menu.personaldata",
        icon: "mdi-account-circle",
        route: "/mypersonaldata",
      },
      // { title: 'menu.settings', icon: 'mdi-cog', route: '/settings' },
    ],
    instructor_items: [
      {
        title: "menu.dayManager",
        icon: "mdi-calendar-clock",
        route: "/daymanager",
      },
      {
        title: "menu.studentManager",
        icon: "mdi-account",
        route: "/studentmanager",
      },
    ],
    loggedout_items: [
      { title: "menu.signin", icon: "mdi-account", route: "/login" },
      { title: "menu.register", icon: "mdi-account-plus", route: "/register" },
    ],
  }),
  computed: {
    user() {
      return store.state.user;
    },
    isInstructor() {
      return store.state.isInstructor
    },
  },
  methods: {
    formatUsername() {
      if (store.state.user && store.state.user.pData != null && store.state.user.pData.firstname != null)
        return store.state.user.pData.firstname.charAt(0).toUpperCase() + store.state.user.pData.firstname.slice(1) + " " +
          store.state.user.pData.name.charAt(0).toUpperCase() + store.state.user.pData.name.slice(1)
      return ""
    },
    logout() {
      logoutUser().then(() => {
        this.$router.push("/login");
      });
    },
  },
  watch: {},
});
</script>

<style></style>

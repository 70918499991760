<template>
  <v-card>
    <v-card-title>{{
      isEdit ? $t("flight_adder.edit") : $t("flight_adder.add_new")
    }}</v-card-title>
    <v-card-text>
      <v-form validate-on="submit lazy" @submit.prevent="submit" ref="form" class="px-3">
        <v-date-input id="fadder-date" :max="new Date()" :label="$t('flight_adder.flight_date')"
          v-model="flightDateCache"></v-date-input>
        <v-text-field id="fadder-takeoff" v-model="flight.takeoff_place" :label="$t('flight_adder.takeoff_place')"
          :rules="inputRules" prepend-icon="mdi-paragliding">
        </v-text-field>
        <v-text-field id="fadder-landing" v-model="flight.landing_place" :label="$t('flight_adder.landing_place')"
          :rules="inputRules" prepend-icon="mdi-parking">
        </v-text-field>
        <v-slider id="fadder-duration" v-model="flight.duration_minutes" :max="60" :min="5" :step="1" thumb-label
          prepend-icon="mdi-timer-sand-full">
        </v-slider>
        <v-textarea id="fadder-comments" v-model="flight.comments" :label="$t('flight_adder.comments')"
          :rules="inputRules" prepend-icon="mdi-comment">
        </v-textarea>
        <v-spacer></v-spacer>
        <v-btn id="fadder-submit" @click="submit" :loading="loading" append-icon="mdi-chevron-right" block
          variant="text">
          {{
            isEdit
              ? $t("flight_adder.confirm_edit")
              : $t("flight_adder.confirm_new")
          }}
        </v-btn>
        <v-btn id="fadder-cancel" @click="cancel" :loading="loading" append-icon="mdi-chevron-right" block
          variant="text">
          {{ $t("flight_adder.cancel") }}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { createDefaultFlightForDiary } from "@/helpers/defs";
import { format } from "date-fns/format";
import { defineComponent } from "vue";
import { VDateInput } from "vuetify/lib/labs/components.mjs";

export default defineComponent({
  components: {
    VDateInput,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      menu: false,
      flightDateCache: null,
      flight: createDefaultFlightForDiary(),
      loading: false,
    };
  },
  computed: {
    inputRules() {
      return [
        (v) => !!v || this.$t("flight_adder.mandatory_field"),
        (v) => v.length >= 3 || this.$t("flight_adder.too_short"),
      ];
    },
    isMinutes() {
      return [
        (v) => Number.isInteger(v) || this.$t("flight_adder.must_be_minutes"),
      ];
    },
    formData() {
      return this.modelValue;
    },
    formattedDate() {
      return this.flight.flight_date
        ? format(this.flight.flight_date, "dd MMM yyyy")
        : "";
    },
    maxDate() {
      return format(new Date(), "yyyy-MM-dd");
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.flight.flight_date = format(this.flightDateCache, "yyyy-MM-dd");
        this.$emit("update:modelValue", { ...this.flight });
        this.$emit("submit");
        this.loading = false;
      }
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  mounted() {
    this.flight = {
      ...this.modelValue,
      flight_date: new Date(this.modelValue.flight_date),
    };
    this.flightDateCache = new Date(this.modelValue.flight_date);
  },
});
</script>

<template>
  <v-form
    @submit.prevent="submit"
    v-model="isFormValid"
    ref="form"
    class="px-3"
    width="auto"
  >
    <v-card
      class="mx-auto pa-12 pb-8"
      elevation="8"
      max-width="448"
      rounded="lg"
    >
      {{ $t("register.createAnAccount") }}
      <v-text-field
        id="register-email"
        v-model="email"
        placeholder="john@doe.com"
        variant="outlined"
        :rules="emailRules"
      ></v-text-field>
      <v-text-field
        id="register-pwd"
        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
        :type="visible ? 'text' : 'password'"
        :placeholder="$t('register.enter_pwd')"
        prepend-inner-icon="mdi-lock-outline"
        variant="outlined"
        @click:append-inner="visible = !visible"
        v-model="password"
        counter
        :hint="$t('register.pwd_hint')"
      ></v-text-field>
      <v-checkbox
        id="register-cgv"
        :label="$t('register.cgv')"
        v-model="cgv_checked"
      ></v-checkbox>
      <v-btn id="register-submit" block @click="submit" :disabled="!cgv_checked">
        {{ $t("register.submit")}}
      </v-btn>
      <v-card id="register-error-msg" v-if="connectionErrorMessage" color="error">
        <v-card-text>{{ connectionErrorMessage }}</v-card-text>
      </v-card>
      <v-btn
        color="blue"
        size="small"
        variant="text"
        append-icon="mdi-chevron-right"
        @click="$router.push('/login')"
      >
        {{ $t("register.alreadyHaveAnAccount") }}
      </v-btn>
    </v-card>
  </v-form>
</template>

<script lang="ts">
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import * as Sentry from "@sentry/browser";
import { defineComponent } from "vue";
import { createDefaultPersonalData, loadPersonalDataDb, updatePersonalDatatDb } from "@/helpers/db/pDataDb";
import { store } from "@/store";
export default defineComponent({
  data: () => ({
    email: "",
    password: "",
    isFormValid: false,
    connectionErrorMessage: null,
    visible: false,
    cgv_checked: false,
  }),
  computed: {
    emailRules() {
      return [
        (v) => !!v || this.$t("register.mandatory_field"),
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("register.invalid_email"),
      ];
    },
  },
  methods: {
    submit() {
      this.connectionErrorMessage = null;
      const auth = getAuth();
      auth.languageCode = this.$i18n.locale;
      createUserWithEmailAndPassword(auth, this.email, this.password)
        .then((auth) => {
          var pData = createDefaultPersonalData()
          updatePersonalDatatDb(pData).then(() => { this.$router.push("/mypersonaldata"); })
          this.$router.push("/mypersonaldata");
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/email-already-in-use": {
              this.connectionErrorMessage = this.$t(
                "register.account_already_existing",
              );
              break;
            }
            case "auth/invalid-email": {
              this.connectionErrorMessage = this.$t("register.invalid_email");
              break;
            }
            default: {
              this.connectionErrorMessage = error.message;
              Sentry.captureException(error);
            }
          }
        });
    },
  },
});
</script>

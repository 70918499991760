import { doc, getDoc, QueryDocumentSnapshot, setDoc } from "firebase/firestore";
import { db } from "@/firebase_init";
import { store } from "@/store";
import { createDefaultMaterialData, MaterialData } from "@/helpers/defs";

const docId = "materialData";

function getCollectionName() {
  return "users/" + store.state.user.uid + "/materialData";
}

async function updateMaterialDatatDb(mData:MaterialData) {
  const ref = doc(db, getCollectionName(), docId).withConverter(
    MaterialDataConverter,
  );
  await setDoc(
    ref,
    new MaterialData(
      mData.pg_manufacturer,
      mData.pg_model,
      mData.pg_color,
      mData.pg_ptv,
      mData.rescue_manufacturer,
      mData.rescue_model,
      mData.rescue_ptv,
      mData.rescue_date,
    ),
  );
}

async function loadMaterialDataDb() {
  const ref = doc(db, getCollectionName(), docId).withConverter(
    MaterialDataConverter,
  );
  const docSnap = await getDoc(ref);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return createDefaultMaterialData();
}

const MaterialDataConverter = {
  toFirestore: (mdata: MaterialData) => {
    return {
      pg_manufacturer: mdata.pg_manufacturer,
      pg_model: mdata.pg_model,
      pg_color: mdata.pg_color,
      pg_ptv: mdata.pg_ptv,
      rescue_manufacturer: mdata.rescue_manufacturer,
      rescue_model: mdata.rescue_model,
      rescue_ptv: mdata.rescue_ptv,
      rescue_date: mdata.rescue_date,
    };
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data();
    return new MaterialData(
      data.pg_manufacturer,
      data.pg_model,
      data.pg_color,
      data.pg_ptv,
      data.rescue_manufacturer,
      data.rescue_model,
      data.rescue_ptv,
      data.rescue_date,
    );
  },
};

export { updateMaterialDatatDb, loadMaterialDataDb };

// todo make object creational function inside class as static methods

import { store } from "@/store";

class FDay {
  public id: string;
  public date:Date;
  public topic: string;
  public place: string;
  public available_seats: number;
  public min_nb_person: number;
  public comments: string;
  public is_cancelled: boolean;
  constructor(id: string,fdate:Date, ftopic:string, fplace:string, available_seats:number, min_nb_person:number, comments:string, is_cancelled:boolean) {
    this.id = id;
    this.date = fdate;
    this.topic = ftopic; // e.g "grands vols", "pente école","marche et vol", ...
    this.place = fplace;
    this.available_seats = available_seats;
    this.min_nb_person = min_nb_person;
    this.comments=comments;
    this.is_cancelled = is_cancelled;
  }
  cancelDay():void {
    this.is_cancelled = true;
  }

  uncancelDay():void {
    this.is_cancelled = false;
  }

  isEnoughEnrollment(nbFreeSeats:number): boolean {
    return this.available_seats - nbFreeSeats >= this.min_nb_person;
  }

  computeDisplayDate(): string{
    return new Date(this.date).toLocaleDateString("fr-FR", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
}

class FDayUser extends FDay{
  enrolledPeople: Array<string>;
  constructor(id: string, fdate: Date, ftopic: string, fplace: string, available_seats: number, min_nb_person: number, comments: string, is_cancelled: boolean, enrolled_people: Array<string>) {
    super(id, fdate, ftopic, fplace, available_seats, min_nb_person, comments, is_cancelled);
    this.enrolledPeople = enrolled_people
  }

  get nbFreeSeats():number{
    return this.available_seats - this.enrolledPeople.length;
  }

 get amIEnrolled(){
    return this.enrolledPeople.indexOf(store.state.user.uid) > -1
  }

}
class FDiary {
  public id: string;
  public flight_date: Date;
  public takeoff_place: string;
  public landing_place: string;
  public duration_minutes: number;
  public comments: string;
  constructor(id:string,fdate:Date, takeoff_place:string, landing_place:string, duration_minutes:number, comments:string) {
    this.id = id;
    this.flight_date = fdate;
    this.takeoff_place = takeoff_place;
    this.landing_place = landing_place;
    this.duration_minutes = duration_minutes;
    this.comments = comments;
  }
}

class MaterialData {
  public pg_manufacturer: string;
  public pg_model: string;
  public pg_color: string;
  public pg_ptv: string;
  public rescue_manufacturer: string;
  public rescue_model: string;
  public rescue_ptv: string;
  public rescue_date: Date|null;
  constructor(manufacturer: string,model:string,color:string,ptv:string,rescue_manufacturer:string,rescue_model:string,
    rescue_ptv:string,rescue_date:Date|null,
  ) {
    this.pg_manufacturer = manufacturer;
    this.pg_model = model;
    this.pg_color = color;
    this.pg_ptv = ptv;
    this.rescue_manufacturer = rescue_manufacturer;
    this.rescue_model = rescue_model;
    this.rescue_ptv = rescue_ptv;
    this.rescue_date = rescue_date;
  }
}

class PersonalData {
  public firstname: string;
  public name: string;
  public email: string;
  public phone: string;
  public urgencyPhone: string;
  constructor(firstname: string, lastname:string, email:string, phone:string, urgencyPhone:string) {
    this.firstname = firstname;
    this.name = lastname;
    this.email = email;
    this.phone = phone;
    this.urgencyPhone = urgencyPhone;
  }
}

class MissionData {
  public selected: Array<boolean>
  constructor(selected: Array<boolean>) {
    this.selected = selected;
  }
}

class Student {
  public user_id: string;
  public firstname: string;
  public lastname: string;
  public phone: string;
  public urgencyPhone: string;
  constructor(user_id: string, firstname:string, lastname:string, phone:string, urgencyPhone:string) {
    this.user_id = user_id;
    this.firstname = firstname;
    this.lastname = lastname;
    this.phone = phone;
    this.urgencyPhone = urgencyPhone;
  }
}
function createDefaultPersonalData():PersonalData {
  return new PersonalData("", "", "", "", "");
}

function createDefaultMaterialData():MaterialData {
  return new MaterialData("", "", "", "", "", "", "", null);
}

function createDefaultMissionData():MissionData {
  return new MissionData([]);
}

function createDefaultFDay():FDayUser {
  return new FDayUser("",new Date(),"Grands vols","",10,3,"",false,[])
}

function createDefaultFlightForDiary():FDiary{
  return new FDiary("",new Date(),"","",10,"")
}


export {
  FDay,
  FDayUser,
  FDiary,
  MaterialData,
  PersonalData,
  MissionData,
  Student,
  createDefaultPersonalData,
  createDefaultMaterialData,
  createDefaultMissionData,
  createDefaultFDay,
  createDefaultFlightForDiary
};

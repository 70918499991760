<!-- eslint-disable -->
<template>
  <v-data-table id="fdiary-table" :headers="translateHeaders"
    :items="flight_list.map((f) => ({ 'fdate': format_date(f.flight_date), ...f }))"
    :sort-by.sync="[{ key: 'flight_date', order: 'desc' }]">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("fdiary.title") }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" width="auto">
          <template v-slot:activator="{ props }">
            <v-btn id="add-flight-btn" v-bind="props" prepend-icon="mdi-folder-plus" variant="tonal">
              {{ $t("fdiary.add_flight") }}
            </v-btn>
          </template>
          <FlightAdderDialog v-model="editedItem" :isEdit="isEdit" @submit="insertOrUpdateFlight"
            @cancel="cancel_add">
          </FlightAdderDialog>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">{{
              $t("fdiary.confirmation_title")
            }}</v-card-title>
            <v-card-text>{{ $t("fdiary.confirmation_delete") }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="fedit-cancel-delete" color="blue-darken-1" variant="text" @click="closeDelete">Cancel</v-btn>
              <v-btn id="fedit-confirm-delete" color="blue-darken-1" variant="text"
                @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon id="fedit-btn" class="me-2" size="small" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon :id="'fedit-delete-'" size="small" @click="deleteItem(item)"> mdi-delete </v-icon>
      <!-- +item.id.slice(0,3) -->
    </template>
    <template v-slot:no-data>
      <span id="no-flight-msg">
        {{ $t("fdiary.empty_msg") }}
      </span>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import FlightAdderDialog from "@/components/FlightAdderDialog.vue";
import {
  deleteFlightDb,
  insertOrUpdateFlightDb,
  loadFlightsDb,
} from "@/helpers/db/fDiaryDb";
import { createDefaultFlightForDiary, FDiary } from "@/helpers/defs";
import { defineComponent } from "vue";


export default defineComponent({
  components: {
    FlightAdderDialog,
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      editedItem: createDefaultFlightForDiary(),
      flight_list: new Array(FDiary),
      isEdit: false,
      loading: false,
    };
  },
  async created() {
    await this.getFlights();
  },

  methods: {
    insertOrUpdateFlight() {
      insertOrUpdateFlightDb(this.editedItem, this.isEdit).then(() => {
        this.loading = false;
      });
      this.close();
    },
    cancel_add() {
      this.close();
    },
    async getFlights() {
      this.flight_list = await loadFlightsDb();
    },
    format_date(date_iso_str: Date) {
      return new Date(date_iso_str).toLocaleDateString("fr-FR", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    editItem(item: FDiary) {
      this.editedItem = Object.assign({}, item);
      this.isEdit = true;
      this.dialog = true;
    },

    deleteItem(item: FDiary) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      deleteFlightDb(this.editedItem.id).then(() => {
        this.closeDelete();
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = createDefaultFlightForDiary();
        this.isEdit = false;
        this.getFlights();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = createDefaultFlightForDiary();
        this.isEdit = false;
        this.getFlights();
      });
    },
  },
  computed: {
    translateHeaders() {
      return [
        { title: this.$t("fdiary.table.date"), key: "fdate" },
        { title: this.$t("fdiary.table.takeoff"), key: "takeoff_place" },
        { title: this.$t("fdiary.table.landing"), key: "landing_place" },
        { title: this.$t("fdiary.table.flighttime"), key: "duration_minutes" },
        { title: this.$t("fdiary.table.comments"), key: "comments" },
        // { title: this.$t('fdiary.table.instructor'), key: 'instructor' },
        { title: this.$t("fdiary.table.actions"), key: "actions" },
      ];
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.close(); // Call the function if 'val' is falsy
      }
    },
    dialogDelete(val) {
      if (!val) {
        this.closeDelete(); // Call the function if 'val' is falsy
      }
    },
  },
});
</script>

<style>
/* Custom class to align text to the left */
.text-left {
  text-align: left;
}
</style>

import { fbapp } from "@/firebase_init";
import { store } from "@/store";
import { connectAuthEmulator, FacebookAuthProvider, getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup, signOut, TwitterAuthProvider } from "firebase/auth";

const auth = getAuth(fbapp);
if (import.meta.env.VITE_USE_EMULATOR === 'true') {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099');
}

async function logoutUser() {
  return signOut(auth);
}

async function signInWithThirdParty(provider: string) {
  var providerInstance = new GoogleAuthProvider();
  switch (provider) {
    case "google":
      providerInstance = new GoogleAuthProvider()
      break;
    case "facebook":
      providerInstance = new FacebookAuthProvider()
      break;
    case "twitter":
      providerInstance = new TwitterAuthProvider()
      break;
    case "apple":
      providerInstance = new OAuthProvider('apple.com');
      break;
    default:
      providerInstance = new GoogleAuthProvider()
  }
  //providerInstance.addScope('email');
  //providerInstance.addScope('name');
  return signInWithPopup(auth, providerInstance)
  // .then((result) => {
  //   // This gives you a Google Access Token. You can use it to access the Google API.
  //   const credential = GoogleAuthProvider.credentialFromResult(result);
  //   const token = credential.accessToken;
  //   // The signed-in user info.
  //   const user = result.user;
  //   // IdP data available using getAdditionalUserInfo(result)
  //   // ...
  // }).catch((error) => {
  //   // Handle Errors here.
  //   const errorCode = error.code;
  //   const errorMessage = error.message;
  //   // The email of the user's account used.
  //   const email = error.customData.email;
  //   // The AuthCredential type that was used.
  //   const credential = GoogleAuthProvider.credentialFromError(error);
  //   // ...
  // });
}

export { logoutUser, signInWithThirdParty };

<template>
  <v-sheet class="mx-auto">
    <v-form @submit.prevent="submit" v-model="isFormValid" ref="form" class="px-3" width="auto">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field id="pdata-firstname" v-model="pData.firstname" :label="$t('pdata.firstname')"
              :rules="nameRules" required></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field id="pdata-lastname" v-model="pData.name" :label="$t('pdata.lastname')" :rules="nameRules"
              required></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field id="pdata-email" v-model="pData.email" label="email" placeholder="johndoe@gmail.com"
              type="email" :rules="emailRules" required></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field id="pdata-phone" v-model="pData.phone" :label="$t('pdata.phone')" :rules="phoneRules"
              required></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field id="pdata-urgencyphone" v-model="pData.urgencyPhone" :label="$t('pdata.urgency_phone')"
              :rules="phoneRules" required></v-text-field>
          </v-col>
          <!-- <v-col cols="12" sm="6">
              <v-file-input :rules="avatarRules" accept="image/png, image/jpeg, image/bmp" label="Avatar"
                placeholder="Pick an avatar" prepend-icon="mdi-camera"></v-file-input>
          </v-col> -->
        </v-row>
      </v-container>

      <v-btn id="pdata-submit" class="me-4" @click="submit" :disabled="!isFormValid">
        {{ $t("confirmation.save") }}
      </v-btn>
      <v-btn id="pdata-cancel" class="me-4" @click="cancel">
        {{ $t("confirmation.cancel") }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-form>
    <ConfirmationDialog ref="confirmationDialog"></ConfirmationDialog>
  </v-sheet>
</template>

<script lang="ts">
import ConfirmationDialog from "@/components/particles/ConfirmationDialog.vue";
import {
  loadPersonalDataDb,
  updatePersonalDatatDb,
} from "@/helpers/db/pDataDb";
import { createDefaultPersonalData } from "@/helpers/defs";
import { defineComponent } from "vue";
export default defineComponent({
  components: {
    ConfirmationDialog,
  },
  data: () => ({
    pData: createDefaultPersonalData(),
    isFormValid: false,
    loading: false,
    dialog: true,
  }),
  computed: {
    avatarRules(){
      return [
        (value)=> !value || !value.length || value[0].size < 2000000 || 'Avatar size should be less than 2 MB!'
    ];
    },
    emailRules() {
      return [
        (v:string) => !!v || this.$t("pdata.mandatory_field"),
        (v:string) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("pdata.invalid_email"),
      ];
    },
    nameRules() {
      return [
        (v:string) => !!v || this.$t("pdata.mandatory_field"),
        (v:string) => !v || v.length >= 3 || this.$t("pdata.too_short"),
      ];
    },
    phoneRules() {
      return [
        (v:string) => !!v || this.$t("pdata.mandatory_field"),
        (v:string) =>
          !v || /^[+]?[0-9-]{10,}$/.test(v) || this.$t("pdata.invalid_phone"),
      ];
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        updatePersonalDatatDb(this.pData).then(() => {
          this.$refs.confirmationDialog.openDialog();
        });
      }
    },
    cancel() {
      loadPersonalDataDb().then((data) => {
        this.pData = data;
      });
    },
  },
  beforeMount() {
    loadPersonalDataDb().then((data) => {
      this.pData = data;
    });
  },
});
</script>
